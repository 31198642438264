/* General Styles */
.contact-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    background-color: #fff;
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  .contact-us-container h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 15px;
  }
  
  .contact-us-container p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    max-width: 600px;
    margin-bottom: 30px;
  }
  
  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  /* Input Field */
  .email-input {
    width: 300px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .email-input:focus {
    border-color: #FF6600;
  }
  
  /* Button */
  .contact-button {
    padding: 10px 20px;
    background-color: #FF6600;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-button:hover {
    background-color: #e65500;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .form-container {
      flex-direction: column;
      width: 100%;
    }
  
    .email-input {
      width: 100%;
    }
  
    .contact-button {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .contact-us-container h1 {
      font-size: 1.5rem;
    }
  
    .contact-us-container p {
      font-size: 0.9rem;
    }
  
    .email-input {
      font-size: 0.9rem;
      padding: 8px 12px;
    }
  
    .contact-button {
      font-size: 0.9rem;
      padding: 8px 12px;
    }
  }
  