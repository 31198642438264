/* NavBar.css - Desktop Styles */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1rem 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    background-color: white;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .logo a {
    color: #FD6F00;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    margin-left: 2rem;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  .nav-links a {
    color: #333;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
    cursor: pointer;
  }
  
  
  .nav-links a:hover {
    color: #FD6F00;
  }
  
  .cv-button {
    background-color: #FD6F00;
    color: white;
    border: none;
    padding: 13px 13px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: opacity 0.3s ease, transform 0.2s ease;
  }
  
  .cv-button:hover {
    opacity: 0.8;
    transform: scale(1.05);
  }
  
  .cv-button:active {
    transform: scale(0.95);
  }
  
  .menu-toggle, .mobile-cv-button {
    display: none;
  }
  
  
  /* NavBarMobile.css - Mobile Responsive Styles */
  @media screen and (max-width: 768px) {
    .navbar-container {
      position: relative;
    }
  
    .nav-links {
      position: fixed;
      top: 70px;
      left: -100%;
      width: 100%;
      height: calc(100vh - 70px);
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: left 0.3s ease-in-out;
      z-index: 999;
    }
  
    .nav-links.active {
      left: 0;
    }
  
    .nav-links li {
      margin: 1rem 0;
    }
  
    .nav-links a {
      font-size: 1.2rem;
    }
  
    .desktop-cv-button {
      display: none;
    }
  
    .mobile-cv-button {
      display: block;
      width: 100%;
      text-align: center;
      margin-top: 1rem;
    }
  
    .mobile-cv-button button {
      background-color: #FD6F00;
      color: white;
      border: none;
      padding: 13px;
      border-radius: 5px;
      font-size: 1rem;
      width: 80%;
      cursor: pointer;
    }
  
    .menu-toggle {
      display: block;
      cursor: pointer;
      z-index: 1000;
    }
  
    .menu-toggle span {
      display: block;
      width: 25px;
      height: 3px;
      background-color: #333;
      margin: 5px 0;
      transition: all 0.3s ease;
    }
  
    .menu-toggle.active span:nth-child(1) {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
  
    .menu-toggle.active span:nth-child(2) {
      opacity: 0;
    }
  
    .menu-toggle.active span:nth-child(3) {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  }