.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  bottom: 0;
  width: 1550px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.footer-links li {
  display: inline;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}
