.projects-container {
    text-align: center;
    padding: 2rem;
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  p {
    color: #666;
    margin-bottom: 2rem;
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 2rem;
  }
  
  .tab {
    background-color: #f5f5f5;
    border: none;
    padding: 0.8rem 1.5rem;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  
  .tab.active {
    background-color: #ff5722;
    color: w    qhite;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 0 1rem;
    margin-left: 100px;
  }
  
  .project-card {
   
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
  }
  
  .project-card img {
    width: 100%;
    height: auto;
  }
  
  .project-card h3 {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
  
  .project-card p {
    color: #999;
    margin-bottom: 1rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .tabs {
      gap: 0.3rem;
    }
  
    .tab {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  
    .project-card h3 {
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 480px) {
    .tab {
      font-size: 0.8rem;
    }
  }
  