* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
}

.home-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  background-color: white;
  border-radius: 8px;
  gap: 20px;
  margin-left: 100px;
  margin-top: -300px;
}

.text-container {
  max-width: 50%;
  margin-left: 75px;
}

.text-container p:first-child {
  font-size: 1.2rem;
}

.class-p {
  color: #000;
  margin-bottom: 10px;
  margin-left: -568px;
}

.text-container h1 {
  color: #FF6A00;
  font-size: 1rem;
  margin-bottom: -30px;
  margin-left: -500px;
}

.text-container h2 {
  font-size: 55px;
  margin-bottom: 10px;
  margin-left: -395px;
}

.span {
  font-size: 55px;
  font-weight: bold;
  margin-left: -70px;
}

.p-class {
  text-align: justify;
}

.text-container p {
  font-size: 1rem;
  line-height: 1.5;
}

.hire-button {
  padding: 15px 30px;
  font-size: 1rem;
  background-color: #FF6A00;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: -460px;
}

.hire-button:hover {
  background-color: #e65b00;
}

.image-container {
  position: relative;
}

.profile-image {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid #FF6A00;
}

.div {
  height: 518px;
}

.social-icons {
  display: flex;
  margin-top: 20px;
  gap: 10px;
  margin-left: 890px;;
}

.social-icons a {
  color: #000;
  font-size: 1.5rem;
  text-decoration: none;
}

.social-icons a:hover {
  color: #FF6A00;
}

/* Tablet Responsive Styles */
@media screen and (max-width: 1024px) {
  .home-container {
    flex-direction: column;
    margin-left: 0;
    padding: 20px;
    text-align: center;
    gap: 30px;
  }

  .text-container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .class-p, 
  .text-container h1, 
  .text-container h2, 
  .span, 
  .hire-button,
  .social-icons {
    margin-left: 0;
  }

  .text-container h1 {
    margin-bottom: 10px;
  }

  .text-container h2 {
    font-size: 40px;
  }

  .span {
    font-size: 40px;
  }

  .social-icons {
    justify-content: center;
  }

  .profile-image {
    width: 250px;
    height: 250px;
  }
}

/* Mobile Responsive Styles */
@media screen and (max-width: 600px) {
  body {
    height: auto;
    padding: 20px;
  }

  .home-container {
    width: 100%;
    margin-left: 0;
    padding: 15px;
    flex-direction: column-reverse; /* Image comes after text */
  }

  .image-container {
    order: 2; /* Ensures image is at the bottom */
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }

  .text-container {
    order: 1; /* Ensures text is at the top */
  }

  .text-container h1 {
    font-size: 0.9rem;
  }

  .text-container h2 {
    font-size: 30px;
  }

  .span {
    font-size: 30px;
  }

  .text-container p {
    font-size: 0.9rem;
    text-align: center;
  }

  .hire-button {
    padding: 12px 25px;
    font-size: 0.9rem;
  }

  .profile-image {
    width: 200px;
    height: 200px;
  }

  .social-icons {
    order: 3; /* Ensures social icons are at the bottom */
    justify-content: center;
    margin-left: 0;
    margin-top: 20px;
  }

  .social-icons a {
    font-size: 1.2rem;
  }
}